import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import "./recharge.css";

function Recharge({ profile, getProfile }) {
    const [bank, setBank] = useState(null);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [payment, setPayment] = useState([]);
    const [newMoney, setNewMoney] = useState("");
    const [loading, setLoading] = useState(false);
    const [listBank, setListBank] = useState([]);
    const [activeOption, setActiveOption] = useState(1);
    const [activeOption2, setActiveOption2] = useState(null);
    const [bankInfo, setBankInfo] = useState(null);
    const [momoInfo, setMomoInfo] = useState(null);

    const handleCopyClick = (text) => {
        const textToCopy = text;
        const tempInput = document.createElement("input");
        tempInput.value = textToCopy;
        document.body.appendChild(tempInput);
        tempInput.select();
        tempInput.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        swal("Thành công", "Copy thành công", "success");
    };
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        axios.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem("user");
                if (token) {
                    config.headers["Authorization"] = `Bearer ${token}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        axios
            .get(`${process.env.REACT_APP_API_URL}/bank/getBankAvailable`)
            .then((res) => {
                setListBank(res.data.data);
                console.log("data:", JSON.stringify(res.data.data, null, 2));
            })
            .catch((err) => {
                setBank(null);
                console.error("Error fetching bank data:", err);
            });
    }, []);

    const handleOptionClick = (option) => {
        setBank(true);
        setActiveOption(option);
    };

    const handleOptionClick2 = (option) => {
        console.log(`Clicked option: ${option}`); 
        setActiveOption2(option);
        setBank(true);
    };

    const onSubmit = (data) => {
        const amount = Number(data.money.replaceAll(".", "").replaceAll(",", ""));
        const formData = {
            money: amount,
            type_payment: "NẠP",
            detail: data.detail || "Nạp",
            status_payment: "Pending",
            user: profile._id
        };

        if (amount <= 0 || isNaN(amount)) {
            swal("Thông báo", "Vui lòng nhập số tiền hợp lệ", "error");
            return;
        }

        if (amount < setting?.minnap) {
            swal("Chú ý", `Số tiền nạp tối thiểu là ${setting?.minnap.toLocaleString('vi-VN')}`, "warning");
            return;
        }

        if (amount > setting?.maxnap) {
            swal("Chú ý", `Số tiền nạp tối đa là ${setting?.maxnap.toLocaleString('vi-VN')}`, "warning");
            return;
        }

        if (activeOption !== 0) {
            const pendingPayments = payment.filter(obj => obj.status_payment === "Pending" && obj.type_payment === "NẠP");
            if (pendingPayments.length >= 1) {
                swal("Thông báo", `Bạn đang có ${pendingPayments.length} lệnh nạp tiền chưa xử lý, không thể tạo thêm lệnh nạp tiền.`, "error");
                return;
            }
            setLoading(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/payment/withDraw`, formData)
                .then(() => {
                    swal("Nạp tiền thành công", "Tiền sẽ được cộng trong vòng 5 phút. Nếu quá lâu vui lòng liên hệ CSKH để được xử lý.", "success");
                    getProfile();
                })
                .catch((err) => {
                    swal("Thất bại", err.response?.data?.message || "Đã xảy ra lỗi", "error");
                })
                .finally(() => setLoading(false));
        } else {

            
            if (activeOption2 === 'momo') {
              
                axios
                .post(`${process.env.REACT_APP_API_URL}/bank/createbank`, {
                    amount: Number(data.money.replaceAll(".", "").replaceAll(",", "")),
                    type: activeOption2,
                    subType: 'momo'
                })
                .then((res) => {
                    setBankInfo(res.data.data);
                    setBank(false);
                    setLoading(false);
                })
                .catch((err) => {
                    swal("Thất bại", err.response?.data?.message || "Ngân hàng bảo trì, vui lòng chọn ngân hàng khác", "error");
                })
            } else {
                
                axios
                .post(`${process.env.REACT_APP_API_URL}/bank/createbank`, {
                    amount: Number(data.money.replaceAll(".", "").replaceAll(",", "")),
                    type: activeOption2,
                    subType: 'bank'
                })
                .then((res) => {
                    setBankInfo(res.data.data);
                    setBank(false);
                    setLoading(false);
                })
                .catch((err) => {
                    swal("Thất bại", err.response?.data?.message || "Ngân hàng bảo trì, vui lòng chọn ngân hàng khác", "error");
                })
        }
    }

    };

    return (
        <>
            {loading && (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            )}
            <div className="recharge">
                {profile && (
                    <>
                        <div className="menu-recharge">
                            <div
                                className={`menu-item-recharge ${activeOption === 0 ? "active" : ""}`}
                                onClick={() => handleOptionClick(0)}
                            >
                                <img src={require("../../../image/onlinebanking.png")} alt="Nạp tiền siêu tốc" />
                                <span>Nạp tiền siêu tốc</span>
                            </div>
                        </div>
                        <div className="content-recharge">
                            <div className="top_title">
                                <div className="txt">Thông tin nạp tiền</div>
                            </div>
                            
                            {bankInfo && (
                                <div className="bank-info">
                                    <div>
                                        Ngân hàng: <b>{bankInfo.bank_provider}</b>
                                    </div>
                                
                                    <div>
                                        STK: <b>{bankInfo.phoneNum}</b>
                                        
                                  
                                    </div>
                                    <div>
                                        Người nhận: <b> {bankInfo.phoneName}</b>
                                    </div>
                                    <div>
                                        Số tiền nạp : <b>{bankInfo.amount.toLocaleString("vi-VN")}</b>
                                    </div>

                                    <div>
                                        Nội dung chuyển khoản: <b>{bankInfo.code}</b>
                                     
                                    </div>
                                    
                                    <div>
                                    {bankInfo.qr_url ? (
                                        <img 
                                            src={`${bankInfo.qr_url}`} 
                                        
                                        />
                                    ) : (
                                        <p>Ảnh không có sẵn</p>
                                    )}
                                    </div>                            
                                </div>
                            )}
                            {momoInfo && (
                                <div className="bank-info">
                                    <div>
                                        VÍ ĐIỆN TỬ : <b>{momoInfo.bank_provider}</b>
                                    </div>
                                
                                    <div>
                                        Số MOMO: <b>{momoInfo.phoneNum}</b>
                                        <span className="copystk" onClick={() => handleCopyClick(momoInfo.phoneNum)}>
                                                    Copy
                                        </span>
                                    </div>
                                    <div>
                                        Người nhận: <b> {momoInfo.phoneName}</b>
                                    </div>
                                    <div>
                                        Số tiền cần nạp : <b>{momoInfo.amount.toLocaleString("vi-VN")}</b>
                                    </div>

                                    <div>
                                        Nội dung chuyển khoản: <b>{momoInfo.code}</b>
                                        <span className="copystk" onClick={() => handleCopyClick(momoInfo.code)}>
                                                    Copy
                                        </span>
                                    </div>
                                    
                                    <div>
                                    {momoInfo.qr_url ? (
                                        <img 
                                            src={`${momoInfo.qr_url}`} 
                                        
                                        />
                                    ) : (
                                        <p>Ảnh không có sẵn</p>
                                    )}
                                    </div>                            
                                </div>
                            )}
                            {activeOption === 0 && (
                                <div className="content_bank">
                                    <h2>Chọn ngân hàng</h2>
                                    <ul className="tab-navigation tab-game tab-bank">
                                        {listBank?.map((item) => (
                                            <li
                                                key={item.code}
                                                className={activeOption2 === item.code ? "active" : ""}
                                                onClick={() => handleOptionClick2(item.code)}
                                            >
                                                <img src={`https://api.vietqr.io/img/${item.napas_code}.png`} alt={item.code} />
                                            </li>
                                        ))}
                                        {!listBank.length && (
                                            <div className="loading">
                                                <div className="loader"></div>
                                            </div>
                                        )}
                                        <li
                                            className={activeOption2 === "momo" ? "active" : ""}
                                            onClick={() => handleOptionClick2("momo")}
                                        >
                                            <img src="/images/logo_momo.png" alt="Momo" />
                                        </li>
                                    </ul>
                                </div>
                            )}
                            {bank && (
                                <>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="input">
                                            <label>Nhập số tiền (VND)</label>
                                            <input
                                                className="ipadd"
                                                type="text"
                                                value={newMoney}
                                                {...register("money", { required: true })}
                                                onClick={() => setNewMoney("")}
                                                onChange={(e) => {
                                                    const value = e.target.value.replaceAll(".", "").replaceAll(",", "");
                                                    setNewMoney(Number(value).toLocaleString("vi-VN"));
                                                }}
                                            />
                                            {errors.money && <span className="error">Vui lòng nhập số tiền hợp lệ</span>}
                                            <button type="submit" className="btn-submit" style={{ marginLeft: "10px" }}>
                                                Xác nhận
                                            </button>
                                        </div>
                                        <div style={{ display: "none" }}>
                                            <input
                                                className="ipadd"
                                                type="text"
                                                {...register("detail")}
                                                placeholder="Nhập mã giao dịch ngân hàng"
                                                defaultValue="Nạp"
                                            />
                                        </div>
                                    </form>

                                    <div className="huongdan">
                                        <div className="title">Hướng dẫn nạp tiền</div>
                                        <ul>
                                            <li>Chuyển khoản đến thông tin ngân hàng/Momo ở trên.</li>
                                            <li>Vui lòng ghi đúng nội dung chuyển khoản gồm có cả tên đăng nhập của bạn</li>
                                            <li>
                                                Sau khi chuyển khoản thành công quý khách điền số tiền đã chuyển khoản vào ô "Nhập số tiền" và bấm xác nhận, tiền sẽ được cộng
                                                trong vòng 5 phút.
                                            </li>
                                            <li>Nếu sau 5 phút tiền không tự động cộng vào tài khoản quý khách, vui lòng liên hệ CSKH để được hỗ trợ nhanh nhất.</li>
                                        </ul>
                                    </div>
                                </>
                            )}

                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default Recharge;
