import axios from "axios";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import Results from "./0_Results";
import History from "../../components/SidebarHistory";
import TabNavigation from "./0_Tab";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Header from "../../components/Header";

import { useNavigate } from "react-router-dom";

function Xoso() {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate("/lottery");
    };
    const [bet, setBet] = useState(null);
    const [second, setSecond] = useState(0);
    const [minute, setMinute] = useState(2);
    const [start, setStart] = useState(false);
    const [dulieunhap, setDulieunhap] = useState(new Date());
    const [newData, setNewData] = useState(null);
    const [update, setUpdate] = useState(0);
    const dates = new Date();
    const currentMinute = dates.getMinutes();
    const currentSecond = dates.getSeconds();
    const [loadGame, setLoadGame] = useState(false);
    const [profile, setProfile] = useState(null);
    const [total, setTotal] = useState(null);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [choose, setChoose] = useState([]);
    const [textArea, setTextArea] = useState(null);
    const [tens, setTens] = useState([]); 
    const [units, setUnits] = useState([]); 
    const [isBetting, setIsBetting] = useState(false); 

    const resettong = () => {
        setTens([]); 
        setChoose([]);
       
    };
    const resetdonvi = () => {
        setUnits([]); 
        setChoose([]);
    }
    const handleTensClick = (number) => {
        let newTens = [];
        let updatedChoose = [];
        if (number === 'all') {
            newTens = [...Array(10).keys()]; 
        } else if (number === 'odds') {
            newTens = [1, 3, 5, 7, 9];
        } else if (number === 'evens') {
            newTens = [0, 2, 4, 6, 8]; 
        } else if (number === 'lower') {
            newTens = [0, 1, 2, 3, 4]; 
        } else if (number === 'upper') {
            newTens = [5, 6, 7, 8, 9]; 
        } else {
            newTens = tens.includes(number)
                ? tens.filter((item) => item !== number)
                : [...tens, number];
        }
        setTens(newTens);
        if (newTens.length === 0) {
            updatedChoose = units.map(u => `x${u}`);
        } else if (units.length === 0) {
            updatedChoose = newTens.map(t => `${t}x`);
        } else {
            updatedChoose = newTens.flatMap(t => units.map(u => `${t}${u}`));
        }
        updatedChoose.sort((a, b) => {
            const numA = parseInt(a, 10);
            const numB = parseInt(b, 10);
            return numA - numB;
        });
        setChoose(updatedChoose);
    };
    const handleUnitsClick = (number) => {
        let newUnits = [];
        let updatedChoose = [];
        if (number === 'all') {
            newUnits = [...Array(10).keys()];
        } else if (number === 'odds') {
            newUnits = [1, 3, 5, 7, 9]; 
        } else if (number === 'evens') {
            newUnits = [0, 2, 4, 6, 8]; 
        } else if (number === 'lower') {
            newUnits = [0, 1, 2, 3, 4]; 
        } else if (number === 'upper') {
            newUnits = [5, 6, 7, 8, 9]; 
        } else {
            newUnits = units.includes(number)
                ? units.filter((item) => item !== number)
                : [...units, number];
        }
        setUnits(newUnits);
        if (tens.length === 0) {
            if (newUnits.length === 0) {
                updatedChoose = []; 
            } else {
                updatedChoose = newUnits.map(u => `x${u}`);
            }
        } else {
            if (newUnits.length === 0) {
                updatedChoose = tens.map(t => `${t}x`);
            } else {
                updatedChoose = tens.flatMap(t => newUnits.map(u => `${t}${u}`));
            }
        }
        updatedChoose.sort((a, b) => {
            const numA = parseInt(a, 10);
            const numB = parseInt(b, 10);
            return numA - numB;
        });
        setChoose(updatedChoose);
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    function rollLottery(res) {
        const interval = setInterval(() => {
            const randomDigits = Math.floor(Math.random() * 90000) + 10000;
            setTotal([{ id_bet: res.data.data[0].id_bet, dacbiet: String(randomDigits) }]);
        }, 100);

        setTimeout(() => {
            clearInterval(interval);
            setTotal(res.data.data);
        }, 2000);
        return () => {
            clearInterval(interval);
        };
    }
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/xs120s/get`).then((res) => {
            setBet(res.data.data);
            setDulieunhap(new Date(res.data.data.createdAt));
            setStart(true);
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/xs120s/getallbet`, {})
            .then((res) => {
                setTotal(res.data.data);
                setNewData(res.data.data);
            })
            .catch(() => setTotal(null));
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            if (profile != res.data.data) {
                setProfile(res.data.data);
                localStorage.setItem("profile", JSON.stringify(res.data.data));
            }
        });
        if (localStorage.getItem("setting") !== null && localStorage.getItem("setting") !== "undefined") {
            setSetting(JSON.parse(localStorage.getItem("setting")));
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
                setSetting(res.data.data[0]);
            });
        }
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            if (Math.floor(120 - (new Date() - dulieunhap) / 1000) < 0) {
                axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
                    setProfile(res.data.data);
                });
                axios.get(`${process.env.REACT_APP_API_URL}/xs120s/get`).then((res) => {
                    setBet(res.data.data);
                    setDulieunhap(new Date(res.data.data.createdAt));
                });
                axios
                    .get(`${process.env.REACT_APP_API_URL}/xs120s/getallbet`, {})
                    .then((res) => {
                        rollLottery(res);
                        setNewData(res.data.data);
                    })
                    .catch(() => setTotal(null));
            }
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, [dulieunhap]);
    useEffect(() => {
        let curTime_second = Math.floor(120 - (dates - dulieunhap) / 1000);

        let myTimeout;

        if (currentMinute === dulieunhap.getMinutes() && currentSecond === dulieunhap.getSeconds()) {
            setStart(true);
            setSecond(second - 1);
            return () => {
                clearTimeout(myTimeout);
            };
        } else if (curTime_second < 120 && curTime_second >= 0) {
            setSecond(curTime_second % 60);
            setMinute((curTime_second - (curTime_second % 60)) / 60);
            setStart(true);
            return () => {
                clearTimeout(myTimeout);
            };
        } else {
            //cập nhật thời gian hiện tại 0.5s/lần
            myTimeout = setTimeout(() => {
                setUpdate(update + 1);
            }, 500);
        }
    }, [update, dulieunhap]);
    useEffect(() => {
        let curTime_second = Math.floor(120 - (dates - dulieunhap) / 1000);
        let myTimeout = 0;
        if (start) {
            setSecond(curTime_second % 60);
            setMinute(Math.floor(curTime_second / 60));

            if (curTime_second > 120 || curTime_second <= 0) {
                setStart(false);
                setMinute(2);
                setSecond(0);
                return () => {
                    clearTimeout(myTimeout);
                };
            }
            myTimeout = setTimeout(() => {
                setSecond(second - 1);
            }, 1000);
        }
        return () => {
            clearTimeout(myTimeout);
        };
    }, [second, start, dulieunhap]);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const openPopup1 = () => {
        setIsOpen1(true);
    };
    const closePopup1 = () => {
        setIsOpen1(false);
    };

    const [isOpen2, setIsOpen2] = useState(false);
    const openPopup2 = () => {
        setIsOpen2(true);
    };
    const closePopup2 = () => {
        setIsOpen2(false);
    };
    const fTextarea = (e) => {
        setTextArea(e.target.value.trim().replace(/[^0-9., ]/g, ""));
        const text = e.target.value.trim().replace(/[^0-9., ]/g, "");
        let splitChar;
        if (text.indexOf(" ") !== -1) {
            splitChar = " ";
        } else if (text.indexOf(".") !== -1) {
            splitChar = ".";
        } else if (text.indexOf(",") !== -1) {
            splitChar = ",";
        } else {
            setChoose([text]);
            return false;
        }
        const arr = text.split(splitChar);
        const uniqueArr = arr.filter((item, index) => arr.indexOf(item) === index);
        setChoose(uniqueArr);
        setTextArea(uniqueArr.join(","));
    };
    const onChoose = (e) => {
        if (choose.includes(e.target.id)) {
            setChoose(choose.filter((item) => item !== e.target.id));
        } else if (choose.length < setting?.maxde) {
            setChoose([...choose, e.target.id]);
        } else {
            swal("Chú ý", `Bạn chỉ được chọn tối đa ${setting?.maxde} số`, "warning");
        }
    };
    const onSubmit = (e) => {
        e.preventDefault();
        if (loadGame == true) return false;
        setLoadGame(true);
        const newData = [];
        let notAllowed = false;
        choose.map((item) => {
            if (item.length == 1) {
                newData.push("0" + Number(item).toString().toLowerCase());
            } else if (item.length > 2) {
                notAllowed = true;
            } else if (item.length == 2) {
                if (item.endsWith('x')) {
                    for (let i = 0; i <= 9; i++) {
                        newData.push(item[0] + i.toString());
                    }
                } else if (item.startsWith('x')) {
                    for (let i = 0; i <= 9; i++) {
                        newData.push(i.toString() + item[1]);
                    }
                } else {
                    newData.push(item);
                }
            }
        });
        if (notAllowed) {
            swal("Thông báo", "Danh sách số đánh có số không hợp lệ.", "error");
            return false;
        }
        if (newMoney == 0 || newMoney == null) {
            swal("Thất bại", "Bạn chưa nhập tiền", "error");
            return false;
        }
        if (newMoney * 1000 > profile.money) {
            swal("Thất bại", "Số dư không đủ", "error");
            return false;
        }
        if (newMoney * 1000 < setting?.mincuoc) {
            swal("Chú ý", `Số tiền cược tối thiểu là ${Number(setting?.mincuoc).toLocaleString("vi-VN")}`, "warning");
            return false;
        }
        if (newMoney * 1000 > setting?.maxcuoc) {
            swal("Chú ý", `Số tiền cược tối đa là ${Number(setting?.maxcuoc).toLocaleString("vi-VN")}`, "warning");
            return false;
        }
        if (!newData || choose.length == 0) {
            swal("Thất bại", "Bạn chưa chọn số đánh", "info");
            return false;
        }
        if (choose.length > setting?.maxde) {
            swal("Chú ý", `Bạn chỉ được chọn tối đa ${setting?.maxde} số`, "warning");
            return false;
        }
        
        if (Number(second) <= 5 && Number(minute) == 0) {
            swal("Đặt cược không thành công.", " Đã hết thời gian cược", "warning");
            return false;
        } else {
            let formData = {
                state: newData.join(" "),
                id: bet?._id,
                type: 15,
                money: newData.length * newMoney * 1000
            };
            if (choose.length == 0) {
                swal("Thất bại", "Bạn chưa chọn số đánh", "info");
            } else {
                axios
                    .post(`${process.env.REACT_APP_API_URL}/history/chooseXS120s`, formData)
                    .then((res) => {
                        swal("Đặt cược thành công", "", "success").then(() => setLoadGame(false));
                        setChoose([]);
                        setTextArea("");
                        setTens([]);
                        setUnits([]);
                        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
                            if (profile != res.data.data) {
                                setProfile(res.data.data);
                                localStorage.setItem("profile", JSON.stringify(res.data.data));
                            }
                        });
                    })
                    .catch((err) =>
                        swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error").then(() => setLoadGame(false))
                    );
            }
        }
    };
    const [newMoney, setNewMoney] = useState(1);
    const numbers = Array.from(Array(100).keys());
    const date0 = new Date();
    const [date, setDate] = useState(date0.getDay());
    const [activeOption, setActiveOption] = useState("1");
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    const [activeOption2, setActiveOption2] = useState("1");
    const handleOptionClick2 = (option) => {
        setActiveOption2(option);
    };
    return (
        <>
            <Header profile={profile} />
            {!bet ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            {bet && (
                <>
                    <div className="container">
                        <div className="game-box-left">
                            <TabNavigation />
                        </div>
                        <div className="game-box-center">
                            <div className="game-detail">
                                <img alt="" src={require(`../../../images/st2p.png`)} />
                                <div>
                                    <div className="game-detail-title">Siêu tốc 2 phút</div>
                                    <div className="game-detail-des">120 giây mở thưởng</div>
                                    <div className="game-detail-des">Phiên: {bet?.id_bet}</div>
                                    <div className="time-box">
                                        <div className="number">
                                            <div className="item">{minute < 10 ? "0" + minute : minute}</div>
                                            <div className="item">:</div>
                                            <div className="item">{second < 10 ? "0" + second : second}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="game-result" onClick={openPopup1}>
                                    <div className="game-result-left">
                                        <img src="/images/icon_time_gold.png" alt="" /> <span>Phiên {total && total[0]?.id_bet}</span>
                                    </div>
                                    <div className="game-result-center">
                                        {total &&
                                            total[0]?.dacbiet?.split("").map((x) => (
                                                <div className="ball">
                                                    <img alt="" src={`/images/ball/${x}.png`} />
                                                </div>
                                            ))}
                                    </div>
                                    <div className="popup-xs">Giải 5 Chữ Số </div>
                                    <table id="table-xsmb" className="table-result table table-bordered table-striped table-xsmb">                                  
                                    <tbody>
                                    <tr>
                                        <th style={{ color: 'navy' }} className="game-detail-title2">TỔNG</th>
                                        <td>
                                            <span id="mb_prize_1" className="game-detail-title4" style={{ color: 'green' }}>
                                                {total && total.length > 0 ? total[0].tong : 'N/A'}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                    </tr>
                                    
                                    <tr>
                                        <th style={{ color: 'navy' }} className="game-detail-title2">CHẴN/LẺ</th>
                                        <td>
                                            <span id="mb_prize_1" className="game-detail-title4"style={{ color: 'green' }}>
                                                {total && total.length > 0 ? total[0].chanle : 'N/A'}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                    </tr>
                                    <tr>
                                        <th style={{ color: 'navy' }} className="game-detail-title2">TÀI/XỈU</th>
                                        <td>
                                            <span id="mb_prize_1" className="game-detail-title4"style={{ color: 'green' }}>
                                                {total && total.length > 0 ? total[0].taixiu : 'N/A'}
                                            </span>
                                        </td>
                                    </tr>
                                    
                                    </tbody>                     
                                    </table>
                                    
                                    <div className="game-result-right">
                                        <KeyboardArrowDownIcon sx={{ fontSize: "30px" }} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ position: "relative" }}>
                                <div className="game-rate">
                                    <div>
                                        <TabNavigation />
                                    </div>
                                    <div>{setting && "Tỷ lệ cược: 1 ăn " + setting.xsn_de1}</div>
                                </div>
                            </div>
                            <div className="main-game">
                                <ul className="tab-navigation tab-game">
        
                                    <li className={activeOption === "1" ? "active" : ""} onClick={() => handleOptionClick("1")}>
                                        Chọn số
                                    </li>
                                    <li className={activeOption === "2" ? "active" : ""} onClick={() => handleOptionClick("2")}>
                                        Nhập số
                                    </li>
                                    <li className={activeOption === "3" ? "active" : ""} onClick={() => handleOptionClick("3")}>
                                        Chọn nhanh
                                    </li>
                                </ul>

                                <div className="main_game">
                                    {activeOption === "3" && (
                                        <div className="text_choose_center">
                                            <div className="state_choose">
                                                {numbers.map((number) => (
                                                    <div
                                                        key={number}
                                                        id={number < 10 ? `0${number}` : number}
                                                        onClick={onChoose}
                                                        className={`choose_xs  ${choose.includes(String(number < 10 ? `0${number}` : number)) ? "chooseItem" : ""}`}>
                                                        {number < 10 ? `0${number}` : number}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    
                                    {activeOption === "2" && (
                                        <div className="text_choose_center">
                                            <div className="cach-choi">
                                                Cách chơi:
                                                <br />
                                                Giữa mỗi cược cần phân cách bởi dấu , hoặc khoảng trống. Ví dụ: 10,20,30 hoặc 10 20 30
                                            </div>
                                            <textarea onChange={fTextarea} value={textArea}></textarea>
                                        </div>
                                    )}

                                    {activeOption === "1" && (
                                        <div className="text_choose_center">
                                        <h3 style={{ color: 'BLACK',  fontWeight: 'bold', textAlign: 'left' }}>Hàng Chục</h3>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', alignItems: 'center' }}>
                                                {[...Array(10).keys()].map(number => (
                                                    <button
                                                        key={number}
                                                        onClick={() => handleTensClick(number)}
                                                        style={{ 
                                                            backgroundColor: isBetting || tens.includes(number) ? '#87CEFA' : '#F0F8FF',
                                                            color: '#000', 
                                                            fontWeight: 'bold',
                                                            padding: '12px 8px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '5px',
                                                            fontSize: '11px',
                                                            cursor: 'pointer',   
                                                            transition: 'background-color 0.3s, transform 0.2s',
                                                        }}
                                                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#B0E0E6'}
                                                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = isBetting || tens.includes(number) ? '#87CEFA' : '#F0F8FF'}
                                                    >
                                                        {number}
                                                    </button>
                                                ))}
                                        <div style={{ marginLeft: '30px', display: 'flex', gap: '3px', flexWrap: 'wrap' }}>
                                            <button
                                                    onClick={() => handleTensClick('all')}
                                                    style={{ 
                                                        backgroundColor: 'gray',
                                                        color: 'white',      
                                                        fontWeight: 'bold',
                                                        padding: '11px 9px', 
                                                        border: '1px solid #ccc',
                                                        borderRadius: '5px',
                                                        fontSize: '11px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s, transform 0.2s',
                                                    }}
                                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#A9A9A9'}
                                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'gray'}
                                                >
                                                    Tất cả
                                                </button>
                                                <button
                                                onClick={() => handleTensClick('odds')}
                                                    style={{ 
                                                        backgroundColor: 'gray', 
                                                        color: 'white', 
                                                        fontWeight: 'bold',
                                                        padding: '7px 7px', 
                                                        border: '1px solid #ccc',
                                                        borderRadius: '5px',
                                                        fontSize: '11px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s, transform 0.2s',
                                                    }}
                                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#A9A9A9'}
                                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'gray'}
                                                >
                                                    LẺ
                                                </button>
                                                <button
                                                    onClick={() => handleTensClick('evens')}
                                                    style={{ 
                                                        backgroundColor: 'gray', 
                                                        color: 'white', 
                                                        fontWeight: 'bold',
                                                        padding: '7px 7px', 
                                                        border: '1px solid #ccc',
                                                        borderRadius: '5px',
                                                        fontSize: '11px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s, transform 0.2s',
                                                    }}
                                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#A9A9A9'}
                                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'gray'}
                                                >
                                                    CHẴN
                                                </button>
                                                <button
                                                    onClick={() => handleTensClick('lower')}
                                                    style={{ 
                                                        backgroundColor: 'gray', 
                                                        color: 'white', 
                                                        fontWeight: 'bold',
                                                        padding: '7px 7px', 
                                                        border: '1px solid #ccc',
                                                        borderRadius: '5px',
                                                        fontSize: '11px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s, transform 0.2s',
                                                    }}
                                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#A9A9A9'}
                                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'gray'}
                                                >
                                                    XỈU
                                                </button>
                                                <button
                                                    onClick={() => handleTensClick('upper')}
                                                    style={{ 
                                                        backgroundColor: 'gray', 
                                                        color: 'white', 
                                                        fontWeight: 'bold',
                                                        padding: '7px 7px', 
                                                        border: '1px solid #ccc',
                                                        borderRadius: '5px',
                                                        fontSize: '11px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s, transform 0.2s',
                                                    }}
                                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#A9A9A9'}
                                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'gray'}
                                                >
                                                    TÀI
                                                </button>
                                                <button
                                                    onClick={resettong}
                                                    style={{ 
                                                        backgroundColor: 'gray', 
                                                        color: 'white', 
                                                        fontWeight: 'bold',
                                                        padding: '9px 1px', 
                                                        border: '1px solid #ccc',
                                                        borderRadius: '5px',
                                                        fontSize: '10px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s, transform 0.2s',
                                                    }}
                                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#A9A9A9'}
                                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'gray'}
                                                >
                                                    CHỌN LẠI
                                                </button>
                                            </div>
                                            </div>
                                            {/* ĐƠN VỊ  */}
                                            <div>
                                                <h3 style={{ color: 'BLACK',  fontWeight: 'bold', textAlign: 'left'  }}>Hàng Đơn Vị</h3>
                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                                                    {[...Array(10).keys()].map(number => (
                                                        <button
                                                            key={number}
                                                            onClick={() => handleUnitsClick(number)}
                                                            style={{
                                                                backgroundColor: isBetting || units.includes(number) ? '#87CEFA' : '#F0F8FF',
                                                                color: '#000', 
                                                                fontWeight: 'bold',
                                                                padding: '12px 8px',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '5px',
                                                                fontSize: '11px',
                                                                cursor: 'pointer',
                                                                transition: 'background-color 0.3s, transform 0.2s',
                                                            }}
                                                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#B0E0E6'}
                                                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = isBetting || units.includes(number) ? '#87CEFA' : '#F0F8FF'}
                                                        >
                                                            {number}
                                                        </button>
                                                    ))}
                                            <div style={{ marginLeft: '30px', display: 'flex', gap: '3px', flexWrap: 'wrap' }}>
                                                <button
                                                    onClick={() => handleUnitsClick('all')}
                                                    style={{ 
                                                        backgroundColor: 'gray',
                                                        color: 'white',      
                                                        fontWeight: 'bold',
                                                        padding: '8px 9px', 
                                                        border: '1px solid #ccc',
                                                        borderRadius: '5px',
                                                        fontSize: '11px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s, transform 0.2s',
                                                    }}
                                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#A9A9A9'}
                                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'gray'}
                                                >
                                                    Tất cả
                                                </button>
                                                <button
                                                onClick={() => handleUnitsClick('odds')}
                                                    style={{ 
                                                        backgroundColor: 'gray', 
                                                        color: 'white', 
                                                        fontWeight: 'bold',
                                                        padding: '7px 7px', 
                                                        border: '1px solid #ccc',
                                                        borderRadius: '5px',
                                                        fontSize: '11px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s, transform 0.2s',
                                                    }}
                                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#A9A9A9'}
                                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'gray'}
                                                >
                                                    LẺ
                                                </button>
                                                <button
                                                    onClick={() => handleUnitsClick('evens')}
                                                    style={{ 
                                                        backgroundColor: 'gray', 
                                                        color: 'white', 
                                                        fontWeight: 'bold',
                                                        padding: '7px 7px', 
                                                        border: '1px solid #ccc',
                                                        borderRadius: '5px',
                                                        fontSize: '11px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s, transform 0.2s',
                                                    }}
                                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#A9A9A9'}
                                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'gray'}
                                                >
                                                    CHẴN
                                                </button>
                                                <button
                                                    onClick={() => handleUnitsClick('lower')}
                                                    style={{ 
                                                        backgroundColor: 'gray', 
                                                        color: 'white', 
                                                        fontWeight: 'bold',
                                                        padding: '7px 7px', 
                                                        border: '1px solid #ccc',
                                                        borderRadius: '5px',
                                                        fontSize: '11px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s, transform 0.2s',
                                                    }}
                                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#A9A9A9'}
                                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'gray'}
                                                >
                                                    XỈU
                                                </button>
                                                <button
                                                    onClick={() => handleUnitsClick('upper')}
                                                    style={{ 
                                                        backgroundColor: 'gray', 
                                                        color: 'white', 
                                                        fontWeight: 'bold',
                                                        padding: '7px 7px', 
                                                        border: '1px solid #ccc',
                                                        borderRadius: '5px',
                                                        fontSize: '11px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s, transform 0.2s',
                                                    }}
                                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#A9A9A9'}
                                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'gray'}
                                                >
                                                    TÀI
                                                </button>
                                                <button
                                                    onClick={resetdonvi}
                                                    style={{ 
                                                        backgroundColor: 'gray', 
                                                        color: 'white', 
                                                        fontWeight: 'bold',
                                                        padding: '9px 1px', 
                                                        border: '1px solid #ccc',
                                                        borderRadius: '5px',
                                                        fontSize: '10px',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s, transform 0.2s',
                                                    }}
                                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#A9A9A9'}
                                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'gray'}
                                                >
                                                    CHỌN LẠI
                                                </button>
                                                </div>
                                                </div>
                                                </div>
                                                </div>
                                                </div>
                                                )}
                                    </div>
                                </div>
                            </div> 

                        <div className="game-box-right footer-game">
                            <ul className="tab-navigation tab-sidebar">
                                <li className={activeOption2 === "1" ? "active" : ""} onClick={() => handleOptionClick2("1")}>
                                    Bảng chọn
                                </li>
                                <li className={activeOption2 === "2" ? "active" : ""} onClick={() => handleOptionClick2("2")}>
                                    Lịch sử
                                </li>
                            </ul>
                            {activeOption2 === "1" && (
                                <form onSubmit={onSubmit}>
                                    {choose.length > 0 && (
                                        <div className="footer-selected">
                                            <div>Đã chọn:</div>
                                            <div>{choose.join(", ")}</div>
                                        </div>
                                    )}
                                    <div className="footer-game-top">
                                        <div className="footer-chip">
                                            <div className="chip-1x" onClick={() => setNewMoney(Number(newMoney) + 1)}></div>
                                            <div className="chip-3x" onClick={() => setNewMoney(Number(newMoney) + 3)}></div>
                                            <div className="chip-5x" onClick={() => setNewMoney(Number(newMoney + 5))}></div>
                                            <div className="chip-10x" onClick={() => setNewMoney(Number(newMoney) + 10)}></div>
                                        </div>
                                        <div>
                                            <input value={newMoney} onChange={(e) => setNewMoney(e.target.value)} min="1" name="money" type="number" />
                                        </div>
                                        <div>
                                            Đã chọn: <b style={{ margin: "0 0.15rem" }}>{choose.length}</b> lô
                                        </div>
                                    </div>
                                    <div className="footer-game-bottom">
                                        <div className="footer-game-money">
                                            <span>Số tiền:</span> <b>{choose.length != 0 && newMoney ? (choose.length * newMoney * 1000).toLocaleString("vi-VN") : 0}</b>
                                            <br />
                                            Số dư: {Math.floor(profile?.money).toLocaleString("vi-VN")}
                                        </div>
                                        <button
                                            type="reset"
                                            className="btn-reset"
                                            style={{ opacity: "0.8" }}
                                            onClick={() => {
                                                setNewMoney(1);
                                                setChoose([]);
                                                setTextArea("");
                                                setTens([]);
                                                setUnits([]);
                                            }}>
                                            Cài lại
                                        </button>
                                        <button type="submit" className="btn-sbmit">
                                            Đặt cược
                                        </button>
                                    </div>
                                </form>
                            )}
                            {activeOption2 === "2" && <History isOpen={true} />}
                        </div>
                    </div>
                </>
            )}

            <Results isOpen={isOpen1} total={total} closePopup={closePopup1} date={total && total[0]?.id_bet} />
        </>
    );
}
export default Xoso;
